import React from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import { Backdrop, CircularProgress } from '@material-ui/core';
import ErrorBoundary from './components/ErrorBoundary';
import './scss/index.scss';
import NotVidJs from './views/bettingconsole/NotVidJs';
// import { MuiPickersUtilsProvider } from '@material-ui/pickers';
// import MomentUtils from '@date-io/moment';
// import axios from 'axios';

// const MobileLogin = React.lazy(() => import('./views/public/MobileLogin'));
// const MobileSignUp = React.lazy(() => import('./views/public/MobileSignUp'));
// const EmailLogin = React.lazy(() => import('./views/public/EmailLogin'));
// const EmailSignUp = React.lazy(() => import('./views/public/EmailSignUp'));
// const EmailForgotPassword = React.lazy(() => import('./views/public/EmailForgotPassword'));
const NotFound = React.lazy(() => import('./views/exceptions/NotFound'));
const AppError = React.lazy(() => import('./views/exceptions/AppError'));
const Layout = React.lazy(() => import('./views/containers/Layout'));
const EntryPage = React.lazy(() => import('./EntryPage'));

export default function App() {
  return (
    <div className="app-root">
      <ErrorBoundary>
        <HashRouter>
          <React.Suspense fallback={<Backdrop open={true}><CircularProgress color="inherit"/></Backdrop>}>
            <Switch>
            <Route path="/notvidjs" name="Not Vid JS" render={props => <NotVidJs {...props}/>} />
              <Route exact path="/404" name="Not Found" render={props => <NotFound {...props}/>} />
              <Route exact path="/500" name="Application Error" render={props => <AppError {...props}/>}/>
              <Route exact path="/:arenaCode/:token/:isPpv" name="Entry Point Page" render={props => <EntryPage {...props}/>}/>
              <Route exact path="/:arenaCode/:token/:isPpv/:parentLanguage" name="Entry Point Page" render={props => <EntryPage {...props}/>}/>
              <Route path="/" name="Home" render={props => <Layout {...props}/>} />
              
            </Switch>
          </React.Suspense>
        </HashRouter>
      </ErrorBoundary>
    </div>
  );
}