import React from "react";

export default function NotVidJs() {
  
  return (
    <div style={{ position:'relative', paddingBottom:'56.25%',height:'0',overflow:'hidden' }}>
        {/* <iframe 
        height="410px" 
        src="https://streamers.ninja/client/source/183?auth=auto&secret=sq85fn4gk59cwuzow73knowvvhnnsrvp&secureToken=nn73kbjhpfd45awq3geba8rfsb9zqkzh&player=hls&theme=six&volume=1&aspectRatio=169&controls=true" 
        width="100%" 
        allowFullScreen="true" 
        id="x76ff" 
        name="ninjastreamers_player" 
        border="0" 
        scrolling="no" 
        frameborder="0" 
        marginheight="0" 
        marginwidth="0" 
        style={{position:'absolute !important', top: '0 !important', left: '0 !important',width: '100% !important',height: '100% !important'}}
        >
        </iframe> */}
        {/* <script src="https://cdn.craffstream.com/dist/craffstream.min.js"></script> */}

        <center>

        <h1>Craffstream Demo</h1>

        <div id="videoplayer" style={{ width: 800, height: 450 }}></div>

        </center>

        {/* <script>craffstream.attachPlayer('videoplayer', 'pcc', 'ramoj');</script> */}
    </div>
    
  );
}
